<template>
  <div id="tracking-slip">
    <div
      style="
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        color: black !important;
        margin-left: 50px;
        margin-right: 50px;
      "
    >
      <header>
        <div class="row">
          <div class="col-7">
            <img
              src="@/assets/images/ched-iso-header-compressed.png"
              width="480"
              class="my-auto"
              alt="CHED Header Logo"
            />
          </div>
          <div class="col-5">
            <img
              src="@/assets/images/bagong-pilipinas-logo.png"
              alt="Bagong Pilipinas Logo"
              class="mr-3"
            />
            <img src="@/assets/images/iso-logo.jpg" alt="ISO Logo" />
          </div>
        </div>
      </header>

      <hr style="border: 1px solid; border-color: black" />
      <br />
      <div
        style="
          font-size: 16px;
          font-family: Arial, Helvetica, sans-serif;
          color: black !important;
        "
        class=""
      >
        <h3 class="mt-5 text-center font-weight-bold">
          ACKNOWLEDGEMENT RECEIPT
        </h3>
      </div>

      <br />

      <div style="">
        <div class="border border-dark px-3 py-2" style="width: 265px">
          <p class="mb-1">Document Tracking No.</p>
          <h2 class="font-weight-bold">
            {{ document.created_at | yearOnly }}-{{ document.id }}
          </h2>
        </div>

        <small class="form-text"
          >In following up, please refer to the tracking no.</small
        >
      </div>

      <br />
      <br />

      <div style="" class="mb-3">
        <p class="text-justify">
          The
          <strong>Commission on Higher Education - Caraga Region</strong>
          hereby formally acknowledges the receipt of your submission, which has
          been duly uploaded to the CHED Caraga Document Tracking System and
          will be forwarded to the personnel incharge for appropriate action.
        </p>
      </div>

      <div class="mb-3">
        <table
          id="acknowledgement-receipt-table"
          class="table table-bordered"
          style="
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
            color: black;
          "
        >
          <tbody>
            <tr class="">
              <td
                style="
                  border: 1px solid;
                  border-color: black !important;
                  font-size: 16px;
                  font-family: Arial, Helvetica, sans-serif;
                "
                width="25%"
              >
                Origin / Source
              </td>
              <th style="border: 1px solid; border-color: black !important">
                {{ document.origin }}
              </th>
            </tr>
            <tr>
              <td style="border: 1px solid; border-color: black !important">
                Document Type
              </td>
              <th style="border: 1px solid; border-color: black !important">
                {{ document.document_type }}
              </th>
            </tr>
            <tr>
              <td style="border: 1px solid; border-color: black !important">
                Document Title
              </td>
              <th style="border: 1px solid; border-color: black !important">
                {{ document.title }}
              </th>
            </tr>

            <tr>
              <td style="border: 1px solid; border-color: black !important">
                Transaction Type
              </td>
              <th style="border: 1px solid; border-color: black !important">
                {{ document.transaction_type }}
              </th>
            </tr>

            <tr>
              <td style="border: 1px solid; border-color: black !important">
                Date Received
              </td>
              <th style="border: 1px solid; border-color: black !important">
                {{ document.created_at | dateTime }}
              </th>
            </tr>

            <tr>
              <td style="border: 1px solid; border-color: black !important">
                Remarks
              </td>
              <th style="border: 1px solid; border-color: black !important">
                {{ document.remarks }}
              </th>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="" class="mb-3">
        <p class="text-justify">
          In compliance with Republic Act 11032, also known as the Ease of Doing
          Business Law, it is recommended that you utilize the provided tracking
          number for follow-up inquiries to ensure clarity and accurate document
          tracking.
        </p>

        <p>
          This receipt is automatically generated and does not necessitate a
          signature.
        </p>
      </div>

      <br /><br />

      <div>
        <img
          src="@/assets/images/rdc-i2fame-logo.png"
          width="115"
          height="110"
          alt="RDC i2Fame Logo"
        />
      </div>

      <br />

      <div class="mb-3">
        <p class="mb-0">CHED Caraga Document Tracking System</p>
        <p>Printed on {{ Date() | dateTime }}</p>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <footer
        style="font-size: 16px; font-family: Arial, Helvetica, sans-serif"
      >
        <hr style="border: 1px solid; border-color: black" />
        <div class="text-center">
          <p class="mb-0">
            Higher Education Development Center Building, Brgy. Ampayon, Butuan
            City, Agusan Del Norte
          </p>
          <p>
            <i class="bi bi-globe"></i> chedcaraga.ph
            <i class="bi bi-envelope ml-3"></i> chedcaraga@ched.gov.ph
            <i class="bi bi-telephone ml-3"></i> (085) 817 3101/816-2406
            /817-0930
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackingSlip",
  data() {
    return {
      document: new Form({
        id: "",
        origin: "",
        document_type: "",
        transaction_type: "",
        title: "",
        status: "",
        deadline: "",
        description: "",
        remarks: "",
        path: "",
        created_at: "",
        encoded_by: "",
      }),
    };
  },
  methods: {
    print(document) {
      this.document.fill(document);
      setTimeout(() => {
        this.$htmlToPaper("tracking-slip");
      }, 1000);
    },
  },
};
</script>
