<template>
  <div class="">
    <div class="card rounded">
      <div class="card-body px-0">
        <div class="table-responsive">
          <table id="outgoing-table" class="table text-nowrap table-hover">
            <thead>
              <th width="" class="pl-4">Tracking No</th>
              <th width="40%">Document Title</th>
              <th width="25%">Origin</th>

              <th>Forwarded to</th>
              <th>Deadline</th>
              <th class="pr-4">Status</th>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!documents.length && !tableLoader">
                <td colspan="11">You have no outgoing records.</td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="11">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr v-for="d in documents" :key="d.id" @click="openDocument(d)">
                <td class="pl-4">
                  {{ d.created_at | yearOnly }}-{{ d.id }}
                  <span v-if="d.is_incoming">
                    <i class="bi bi-arrow-down"></i>
                  </span>
                  <span v-else> <i class="bi bi-arrow-up"></i> </span>
                </td>
                <td style="font-weight: 500" class="text-wrap">
                  <i :class="[getFlagColor(d.priority_level)]"></i>{{ d.title }}
                </td>
                <td class="text-wrap">{{ d.origin }}</td>

                <td>
                  {{ `${d.first_name} ${d.last_name}` }}
                  <small class="form-text text-muted">{{
                    d.forwarded_at | completeDateTime
                  }}</small>
                </td>
                <td>
                  <div v-if="d.deadline !== null">
                    {{ d.deadline | dateTime }}
                    <small class="form-text text-muted"
                      >{{ d.deadline | dueIn }} ({{
                        d.transaction_type
                      }})</small
                    >
                  </div>
                  <span v-else>N/A</span>
                </td>
                <td class="pr-4">
                  <span class="badge badge-pill badge-danger">{{
                    d.status
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <DocumentDetailsModal ref="DocumentDetailsModal" :canAddActionTaken="false">
    </DocumentDetailsModal>
  </div>
</template>

<script>
import DocumentDetailsModal from "./DocumentDetailsModal.vue";
import { getFlagColor } from "../../../js/helpers";

export default {
  name: "Outgoing",
  components: {
    DocumentDetailsModal,
  },
  data() {
    return {
      documents: [],
      tableLoader: false,
    };
  },
  methods: {
    openDocument(document) {
      this.$refs.DocumentDetailsModal.show(document);
    },
    getOutgoing() {
      this.tableLoader = true;
      axios
        .get("api/documents/outgoing")
        .then((res) => {
          this.documents = res.data;
          this.tableLoader = false;
          this.$emit("updateBadges");
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
    getFlagColor,
  },
  mounted() {
    this.getOutgoing();
  },
};
</script>

<style scoped>
#outgoing-table tr {
  cursor: pointer !important;
}
</style>
