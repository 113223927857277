<template>
  <div>
    <div class="modal" data-backdrop="static" id="forward-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="forwardToUser">
            <div class="modal-body">
              <div class="alert alert-warning mb-3">
                <i class="bi bi-exclamation-circle-fill mr-1"></i>
                <strong>Please note:</strong>
                Reference copy documents are solely for your reference and
                cannot be forwarded.
              </div>
              <ul class="list-group">
                <li
                  v-for="item in selected"
                  :key="item.id"
                  class="list-group-item"
                >
                  <strong
                    >[DTN: {{ item.created_at | yearOnly }}-{{
                      item.id
                    }}]</strong
                  >
                  : {{ item.title }}
                </li>
              </ul>
              <small class="form-text text-muted"
                >You can also forward multiple documents by ticking the
                boxes.</small
              >
              <br />
              <div class="form-group">
                <label for="">Forward to</label>

                <Multiselect
                  v-model="recipient"
                  :options="users"
                  track-by="id"
                  :custom-label="getFullname"
                  placeholder="Choose"
                  :allow-empty="false"
                  required
                ></Multiselect>
              </div>

              <div class="form-check">
                <input
                  id="has-reference-copy"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="hasReferenceCopy"
                />
                <label for="has-reference-copy" class="form-check-label">
                  Create reference copy
                </label>
              </div>

              <div v-if="hasReferenceCopy" class="form-group mt-3">
                <label for="">Cc:</label>
                <Multiselect
                  v-model="recipients"
                  :options="users"
                  track-by="id"
                  :custom-label="getFullname"
                  placeholder="Choose"
                  :allow-empty="false"
                  :multiple="true"
                  required
                ></Multiselect>
              </div>

              <div class="form-group mt-3">
                <label for="">Desired action (Optional)</label>
                <Multiselect
                  v-model="action"
                  :options="actions"
                  placeholder="Choose"
                  :allow-empty="true"
                  required
                ></Multiselect>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button id="forward-btn" type="submit" class="btn btn-primary">
                Forward
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "ForwardModal",
  components: {
    Multiselect,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  data() {
    return {
      recipients: [],
      recipient: "",
      users: [],
      actions: [
        "For appropriate action",
        "For acknowledgement",
        "For indorsement",
        "For information",
        "For discussion",
        "For reply",
        "For approval",
        "For correction",
        "For comments",
        "For circulation",
        "For justification",
        "For coordination",
        "For investigation",
        "For representation",
        "For file",
      ],
      hasReferenceCopy: false,
      action: "",
    };
  },
  methods: {
    openModal() {
      this.recipients = [];
      this.hasReferenceCopy = false;
      this.action = "";
      $("#forward-modal").modal("show");
      this.getInternalUsers();
    },

    hasCopy() {
      return this.selected.some((doc) => doc.is_copy === 1);
    },

    forwardToUser() {
      if (this.recipient == null || this.recipient == "") {
        toastr.error("Please select a recipient.", "Error");
        return;
      }

      if (this.hasReferenceCopy && !this.recipients.length) {
        toastr.error("Please select recipient for reference copy.", "Error");
        return;
      }

      if (this.hasCopy()) {
        toastr.error(
          "Unable to forward a reference copy document. Please exclude it to proceed.",
          "Error"
        );
        return;
      }

      let b = document.getElementById("forward-btn");
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Forwarding...`;

      /*  this.recipients = Array.isArray(this.recipients)
        ? this.recipients
        : [this.recipients]; */

      let params = {
        documents: this.selected,
        recipient: this.recipient,
        recipients: this.recipients,
        has_reference_copy: this.hasReferenceCopy,
        action: this.action,
      };

      axios
        .put("api/documents/forward", params)
        .then((res) => {
          toastr.success(res.data.msg, "Information");
          b.innerHTML = "Forward";
          b.disabled = false;
          $("#forward-modal").modal("hide");
          this.$emit("hasUpdates");
        })
        .catch((err) => {
          toastr.error(err.response.data.msg, "Error");
          b.innerHTML = "Forward";
          b.disabled = false;
        });
    },

    getInternalUsers() {
      axios
        .get("api/users/internal")
        .then((res) => {
          this.users = res.data;
        })
        .catch((err) => {});
    },

    getFullname({ first_name, last_name }) {
      return `${first_name} ${last_name} `;
    },
  },
  watch: {
    recipients(newVal) {
      // Ensure recipients is always an array, even for single selections
      if (!Array.isArray(newVal) && newVal !== null) {
        this.recipients = [newVal];
      }
    },
  },

  mounted() {},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
