<template>
  <div id="cts-main">
    <div class="card mb-3 rounded">
      <div class="card-body">
        <!---------------------- Tabs ------------------------------>
        <ul class="nav nav-tabs" id="cts-tabs">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="Incoming"
              href="#"
              @click.prevent="tabActive('Incoming')"
              ><i class="bi bi-arrow-down"></i> Incoming
              <span class="badge badge-danger" v-show="badges.incoming">{{
                badges.incoming
              }}</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Outgoing"
              href="#"
              @click.prevent="tabActive('Outgoing')"
              ><i class="bi bi-arrow-up"></i> Outgoing
              <span class="badge badge-secondary" v-show="badges.outgoing">{{
                badges.outgoing
              }}</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="MyDocuments"
              href="#"
              @click.prevent="tabActive('MyDocuments')"
              ><i class="bi bi-files mr-1"></i> My Documents</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="CreateRecord"
              data-toggle="tab"
              href="#"
              @click.prevent="tabActive('CreateRecord')"
            >
              <i class="bi bi-cloud-plus mr-1"></i> Create Record</a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="SearchDatabase"
              href="#"
              @click.prevent="tabActive('SearchDatabase')"
              ><i class="bi bi-search mr-1"></i> All Records</a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="Reports"
              @click.prevent="tabActive('Reports')"
              href="#"
              ><i class="bi bi-bar-chart-line mr-1"></i> Reports
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="">
      <component v-bind:is="currentTab" v-on:updateBadges="getBadges">
      </component>
    </div>
  </div>
</template>

<script>
import Incoming from "./components/Incoming.vue";
import MyDocuments from "./components/MyDocuments.vue";
import Reports from "./components/Reports.vue";
import CreateRecord from "./components/CreateRecord.vue";
import SearchDatabase from "./components/SearchDatabase.vue";
import Outgoing from "./components/Outgoing.vue";

export default {
  name: "Main",
  components: {
    Incoming,
    MyDocuments,
    Reports,
    CreateRecord,
    SearchDatabase,
    Outgoing,
  },
  data() {
    return {
      currentTab: "Incoming",
      specialOrderNumber: "",
      verifiedStudents: "",
      logs: "",
      students: [],
      logs_program: "",
      showTable: false,
      hei_programs: [],
      csvData: "",
      programs: "",
      badges: {
        incoming: 0,
        outgoing: 0,
      },
    };
  },
  methods: {
    tabActive(element) {
      this.currentTab = element;
      $("#cts-tabs .nav-link").removeClass("active");
      $("#" + element).addClass("active");
    },
    getBadges() {
      axios
        .get("api/documents/badges/" + this.$store.state.user.id)
        .then((res) => {
          this.badges = res.data;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getBadges();
  },
};
</script>
