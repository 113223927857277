<template>
  <div>
    <div class="card mb-3 rounded">
      <div class="card-body">
        <form
          @submit.prevent="
            search(DEFAULT_URL, { searchLoader: { enabled: true } })
          "
        >
          <div class="form-row">
            <div class="col-auto">
              <div class="form-group">
                <label for="">Tracking No</label>
                <input
                  type="search"
                  maxlength="5"
                  class="form-control"
                  placeholder="Tracking No."
                  v-model="params.id"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label for="">Document Title</label>

                <input
                  type="search"
                  class="form-control"
                  v-model="params.title"
                  placeholder="Search document title that contains keyword"
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label for="">Origin</label>

                <input
                  type="search"
                  class="form-control"
                  v-model="params.origin"
                  placeholder="Contains keyword"
                />
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">Document Type</label>
                <select
                  name=""
                  class="form-control"
                  v-model="params.document_type"
                >
                  <option value="">All</option>
                  <option value="Acknowledgement">Acknowledgement</option>
                  <option value="Complaint">Complaint</option>
                  <option value="Compliance">Compliance</option>
                  <option value="Data Request">Data Request</option>
                  <option value="Deficiency">Deficiency</option>
                  <option value="Information">Information</option>
                  <option value="Meeting">Meeting</option>
                  <option value="Memorandum">Memorandum</option>
                  <option value="Orientation">Orientation</option>
                  <option value="Program Application">Program App.</option>
                  <option value="Letter">Letter</option>
                  <option value="Indorsement">Indorsement</option>
                  <option value="Invitation">Invitation</option>
                  <option value="Issuance">Issuance</option>
                  <option value="Report">Report</option>
                  <option value="Request">Request</option>
                  <option value="Scholarship">Scholarship</option>
                  <option value="UNIFAST">UNIFAST</option>
                  <option value="Special Order">Special Order</option>
                  <option value="Training">Training</option>
                  <option value="Voucher">Voucher</option>
                </select>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">Transaction Type</label>
                <select
                  name=""
                  class="form-control"
                  v-model="params.transaction_type"
                >
                  <option value="">All</option>
                  <option value="Simple">Simple</option>
                  <option value="Complex">Complex</option>
                  <option value="Highly Tech.">Highly Tech.</option>
                  <option value="N/A">N/A</option>
                </select>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">Status</label>
                <select name="" class="form-control" v-model="params.status">
                  <option value="">All</option>
                  <option value="Pending">Pending</option>
                  <option value="Closed">Closed</option>
                </select>
              </div>
            </div>

            <div class="col-auto">
              <label for="">Encoded by</label>
              <select
                name=""
                class="form-control"
                id=""
                v-model="params.encoded_by"
              >
                <option value="">All</option>
                <option v-for="user in users" :key="user.id" :value="user.id">
                  {{ `${user.first_name} ${user.last_name}` }}
                </option>
              </select>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">From</label>
                <input
                  type="date"
                  v-model="params.start_date"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <label for="">To</label>
                <input
                  type="date"
                  v-model="params.end_date"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">Category</label>
                <select name="" class="form-control" v-model="params.category">
                  <option value="">All</option>
                  <option value="1">Incoming</option>
                  <option value="0">Outgoing</option>
                </select>
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <label for="" class="invisible">Button</label>
                <button
                  type="submit"
                  class="form-control btn btn-primary"
                  style="background-color: #321fdb; color: #fff"
                >
                  <span v-if="!searchLoader">SEARCH</span>
                  <span v-else class="spinner-border spinner-border-sm"></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div v-if="documents.length" class="card rounded">
      <div class="card-body px-0">
        <div class="">
          <div class="px-4 d-flex justify-content-between">
            <div class="form-inline">
              <div class="form-group">
                <label for="">No. of rows</label>
                <select
                  name=""
                  id=""
                  class="form-control form-control-sm ml-2"
                  v-model="params.per_page"
                  @change="
                    search(DEFAULT_URL, {
                      searchLoader: { enabled: false },
                    })
                  "
                >
                  <option value="10">10</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div class="d-flex justify-content-right">
              <div class="my-auto mr-2">
                <span
                  >{{
                    `${pagination.from || 0}-${pagination.to || 0} of ${
                      pagination.total || 0
                    }`
                  }}
                </span>
              </div>
              <nav>
                <ul class="pagination mb-0 pagination-sm">
                  <li
                    class="page-item"
                    :class="{ disabled: !pagination.prev_page_url }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="
                        search(pagination.prev_page_url, {
                          searchLoader: { enabled: false },
                        })
                      "
                    >
                      <span aria-hidden="true"
                        ><i class="bi bi-chevron-left"></i
                      ></span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <li
                    class="page-item"
                    :class="{ disabled: !pagination.next_page_url }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="
                        search(pagination.next_page_url, {
                          searchLoader: { enabled: false },
                        })
                      "
                    >
                      <span aria-hidden="true"
                        ><i class="bi bi-chevron-right"></i
                      ></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div class="table-responsive">
            <table
              id="search-database-table"
              class="table table-hover text-nowrap mt-3"
              v-show="documents.length"
            >
              <thead>
                <th width="" class="pl-4">Tracking No</th>
                <th width="40%">Document Title</th>
                <th width="25%">Origin</th>

                <th>Owner</th>
                <th>Date Encoded</th>
                <th class="pr-4">Status</th>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-if="!documents.length && !tableLoader"
                >
                  <td colspan="10">No results found</td>
                </tr>
                <tr class="text-center" v-if="tableLoader">
                  <td colspan="10">
                    <div class="spinner-border spinner-border-sm mr-1"></div>
                    Loading
                  </td>
                </tr>
                <tr
                  :class="{ 'table-secondary': d.status == 'Closed' }"
                  v-for="d in documents"
                  :key="d.id"
                  @click="openDocument(d)"
                >
                  <td class="pl-4">
                    <i
                      v-if="d.status == 'Closed'"
                      class="bi bi-lock-fill mr-1"
                    ></i>
                    {{ d.created_at | yearOnly }}-{{ d.id }}
                    <span v-if="d.is_incoming">
                      <i class="bi bi-arrow-down"></i>
                    </span>
                    <span v-else>
                      <i class="bi bi-arrow-up"></i>
                    </span>
                  </td>
                  <td style="font-weight: 500" class="text-wrap">
                    <i :class="[getFlagColor(d.priority_level)]"></i
                    >{{ d.title }}
                  </td>

                  <td class="text-wrap">{{ d.origin }}</td>

                  <td>
                    {{ `${d.first_name} ${d.last_name}` }}
                  </td>
                  <td>{{ d.created_at | dateTime }}</td>
                  <td class="pr-4">
                    <span
                      v-if="d.status == 'Pending'"
                      class="badge badge-pill badge-danger"
                      >{{ d.status }}</span
                    >
                    <span v-else class="badge badge-pill badge-secondary">{{
                      d.status
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <DocumentDetailsModal ref="DocumentDetailsModal" :canAddActionTaken="false">
    </DocumentDetailsModal>
  </div>
</template>

<script>
import DocumentDetailsModal from "./DocumentDetailsModal.vue";
import { getFlagColor } from "../../../js/helpers";

export default {
  name: "SearchDatabase",
  components: {
    DocumentDetailsModal,
  },
  data() {
    return {
      origins: [],
      column: "id",
      input: "",
      users: [],
      origin: "",
      searchLoader: false,
      transaction_type: "",
      document_type: "",
      status: "",
      tableLoader: false,
      documents: [],
      pagination: {},
      params: {
        id: "",
        title: "",
        transaction_type: "",
        document_type: "",
        origin: "",
        status: "",
        encoded_by: "",
        per_page: 10,
        start_date: "",
        end_date: "",
        category: "",
      },
      DEFAULT_URL: "api/documents/search",
    };
  },
  methods: {
    getFlagColor,
    openDocument(document) {
      this.$refs.DocumentDetailsModal.show(document);
    },
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
    search(page_url = null, options) {
      if (this.params.start_date !== "" || this.params.end_date !== "") {
        if (this.params.start_date === "" || this.params.end_date === "") {
          toastr.error("Make sure to input the start and end date.", "Oops!");
          return;
        }
      }

      this.searchLoader = options.searchLoader.enabled;
      page_url = page_url || this.DEFAULT_URL;

      const params = {
        ...this.params,
      };

      axios
        .get(page_url, {
          params,
        })
        .then((res) => {
          this.searchLoader = false;
          if (!res.data.data.length) toastr.error("No results found", "Oops!");
          this.makePagination(res.data);
          this.documents = res.data.data;
        })
        .catch((err) => {
          this.searchLoader = false;
        });
    },
    getInternalUsers() {
      axios
        .get("api/users/internal")
        .then((res) => {
          this.users = res.data;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getInternalUsers();
  },
};
</script>

<style scoped>
#search-database-table tr {
  cursor: pointer !important;
}
</style>
