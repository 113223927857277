<template>
  <div>
    <div class="row">
      <div class="col-auto">
        <div class="card rounded">
          <div class="card-body">
            <div class="text-center" v-if="loader">
              <div class="spinner-border text-secondary" role="status"></div>
              <h6 class="mb-0 mt-2">Generating Statistical Table</h6>
              <span class="form-text text-muted">
                Please wait. This might take a few seconds.
              </span>
            </div>

            <div v-else>
              <p>
                Please input the start and end date for the desired date range.
              </p>
              <div class="form-inline">
                <label for="">From</label>
                <input
                  type="date"
                  v-model="params.startDate"
                  class="mx-2 form-control"
                />
                <label for="">To</label>
                <input
                  type="date"
                  v-model="params.endDate"
                  class="mx-2 form-control"
                />
                <button class="btn btn-primary" @click="getStatisticalData">
                  Go
                </button>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th colspan="2">USER METRICS</th>
                      </tr>
                      <tr>
                        <td>My Documents</td>
                        <th>{{ stats.my_documents }}</th>
                      </tr>
                      <tr>
                        <td>Incoming <i class="bi bi-arrow-down"></i></td>
                        <th>
                          {{ stats.incoming }} ({{ stats.incoming_percentage }})
                        </th>
                      </tr>
                      <tr>
                        <td>Outgoing <i class="bi bi-arrow-up"></i></td>
                        <th>
                          {{ stats.outgoing }} ({{ stats.outgoing_percentage }})
                        </th>
                      </tr>
                      <tr>
                        <td>Number of Actions Taken</td>
                        <th>{{ stats.number_of_documents_processed }}</th>
                      </tr>
                      <tr>
                        <td>Number of Documents Encoded</td>
                        <th>{{ stats.number_of_documents_encoded }}</th>
                      </tr>
                      <tr>
                        <td class="text-danger">
                          Total Number of Overdue Documents
                        </td>
                        <th class="text-danger">
                          {{ stats.overdues }} ({{ stats.overdues_percentage }})
                        </th>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th colspan="2">STATUS</th>
                      </tr>
                      <tr>
                        <td>Closed/Archived</td>
                        <th>
                          {{ stats.closed }} ({{ stats.closed_percentage }})
                        </th>
                      </tr>

                      <tr>
                        <td class="text-danger">Pending</td>
                        <th class="text-danger">
                          {{ stats.pending }} ({{ stats.pending_percentage }})
                        </th>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th colspan="2">TRANSACTION TYPE</th>
                      </tr>
                      <tr>
                        <td>Simple (3 days)</td>
                        <th>
                          {{ stats.simple }} ({{ stats.simple_percentage }})
                        </th>
                      </tr>
                      <tr>
                        <td>Complex (7 days)</td>
                        <th>
                          {{ stats.complex }} ({{ stats.complex_percentage }})
                        </th>
                      </tr>
                      <tr>
                        <td>Highly Technical (21 days)</td>
                        <th>
                          {{ stats.highly_technical }} ({{
                            stats.highly_technical_percentage
                          }})
                        </th>
                      </tr>
                      <tr>
                        <td>N/A</td>
                        <th>{{ stats.na }} ({{ stats.na_percentage }})</th>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th colspan="2">DOCUMENT TYPE</th>
                      </tr>
                      <tr v-for="doc in stats.document_types" :key="doc.id">
                        <td>{{ doc.document_type }}</td>
                        <th>{{ doc.count }} ({{ doc.percentage }})</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reports",
  data() {
    return {
      stats: {
        closed: "",
        closed_percentage: "",
        complex: "",
        complex_percetange: "",
        document_types: [],
        highly_technical: "",
        highly_technical_percentage: "",
        incoming: "",
        incoming_percentage: "",
        my_documents: "",
        na: "",
        na_percentage: "",
        number_of_documents_encoded: "",
        number_of_documents_processed: "",
        outgoing: "",
        outgoing_percentage: "",
        overdues: "",
        overdues_percentage: "",
        pending: "",
        pending_percentage: "",
        simple: "",
        simple_percentage: "",
      },
      loader: false,
      dateFilterLoader: false,
      DEFAULT_URL: "api/documents/my-documents",
      documents: [],
      params: {
        startDate: "",
        endDate: "",
      },
    };
  },
  methods: {
    getStatisticalData() {
      this.loader = true;
      axios
        .get("api/dts/statistical-data", {
          params: this.params,
        })
        .then((response) => {
          this.loader = false;
          this.setStatisticalData(response.data);
        })
        .catch((error) => {
          this.loader = false;
        });
    },
    setStatisticalData(stats) {
      Object.assign(this.stats, stats);
    },
  },
  mounted() {
    this.getStatisticalData();
  },
};
</script>
